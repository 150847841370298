.sprite.mandiri {
  background-position: -40px -232px;
  width: 40px;
  height: 40px;
}

.sprite.bca {
  background-position: 0px -232px;
  width: 40px;
  height: 40px;
}

.sprite.bsi {
  background-position: 0px -70px;
  width: 40px;
  height: 40px;
}

.sprite.bni {
  background-position: -120px -232px;
  width: 40px;
  height: 40px;
}

.sprite.bri {
  background-position: 0px -430px;
  width: 40px;
  height: 40px;
}

.sprite.permata {
  background-position: -80px -232px;
  width: 40px;
  height: 40px;
}

.sprite.gopay {
  background-position: -80px -430px;
  width: 40px;
  height: 40px;
}

.sprite.shopee {
  background-position: -120px -430px;
  width: 40px;
  height: 40px;
}

.sprite.alfamart-ipay {
  background-position: -80px -80px;
  width: 40px;
  height: 40px;
}

.sprite.alfamart {
  background-position: -40px -470px;
  width: 40px;
  height: 40px;
}

.sprite.bank-transfer {
  background-position: 0px -392px;
  width: 40px;
  height: 40px;
}

.sprite.qris {
  background-position: -40px -430px;
  width: 40px;
  height: 40px;
}

.sprite.indomaret {
  background-position: -40px -80px;
  width: 40px;
  height: 40px;
}

.sprite.wallet {
  background-position: 0px -392px;
  width: 40px;
  height: 40px;
}

.sprite {
  background-image: url("../../assets/sprite.png");
  background-size: 160px 510px;
  background-repeat: no-repeat;
  display: block;
}
